import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import CellTowerIcon from "@mui/icons-material/CellTower";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FolderIcon from "@mui/icons-material/Folder";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import GroupIcon from "@mui/icons-material/Group";
import HelpIcon from "@mui/icons-material/Help";
import CircleIcon from '@mui/icons-material/Circle';
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import LanguageIcon from "@mui/icons-material/Language";
import NotificationsIcon from '@mui/icons-material/Notifications';
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import MapIcon from "@mui/icons-material/Map";
import MenuIcon from "@mui/icons-material/Menu";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Menu from '@mui/material/Menu';
import MenuItem from "@mui/material/MenuItem";
import Popper from '@mui/material/Popper';
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import { Badge } from "@mui/material";
import Popover from "@mui/material/Popover";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import { styled, useTheme } from "@mui/material/styles";
import { captureException } from "@sentry/react";
import { useContext, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import VTSLogo from "../../assets/vtsLogo.png";
import useAxiosPrivateUserMng from "../../axios/useAxiosPrivateUserMng";
import { AccountContext } from "../../container/Auth/Accounts/Account";
import { MUIWrapperContext } from "../../utils/MUI/MUIWrapper";
import NestedListItem from "../NestedListItem/NestedListItem";
import SpeedDialHelper from "./SpeedDial";

const lngs = [
  { language: "en", nativeName: "English" },
  { language: "es", nativeName: "Spanish" },
];
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(5)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const AppHeader = (props) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const muiUtils = useContext(MUIWrapperContext);
  const navigate = useNavigate();
  const { logout } = useContext(AccountContext);
  const { PARENTID, USERID } = useSelector((state) => state.AuthReducer);
  const userRole = useSelector((state) => state.AuthReducer.role);
  const userLoginStatus = useSelector(
    (state) => state.AuthReducer.userLoginStatus
  );
  const axiosPrivateUserMng = useAxiosPrivateUserMng();
  const [open, setOpen] = useState(() => {
    const savedState = localStorage.getItem("drawerState");
    return savedState === "false" ? false : true;
  });
  const location = useLocation();
  const currentRoute = useMemo(() => {
    return location.pathname;
  }, [location.pathname]);
  const [currentLanguage, setCurrentLanguage] = useState("en");
  const [navItems, setNavItems] = useState([]);
  // Popover Logic
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openPop = Boolean(anchorEl);
  const id = openPop ? "simple-popover" : undefined;

  const internalModel = [
    {
      type: "Default",
      icon: <FolderCopyIcon />,
      items: [
        {
          name: <Trans i18nKey="common.activeProjects"></Trans>,
          icon: <FolderIcon />,
          navDest: "/",
          valid: ["SUPERADMIN", "CUSTOMER", "USER", "INTERNAL"],
        },
        {
          name: <Trans i18nKey="DrawerInternal.dashboard"></Trans>,
          icon: <DashboardIcon />,
          navDest: "/dashboard",
          valid: ["SUPERADMIN", "CUSTOMER", "USER", "INTERNAL"],
        },
        {
          name: <Trans i18nKey="DrawerInternal.mySites"></Trans>,
          icon: <LocationSearchingIcon />,
          navDest: "/mysites",
          valid: ["SUPERADMIN", "CUSTOMER", "USER", "INTERNAL"],
        },
        {
          name: "My Captures",
          icon: <ViewInArIcon />,
          navDest: "/mycaptures",
          valid: ["SUPERADMIN", "CUSTOMER", "USER", "INTERNAL"],
        },
        {
          name: "My Uploads",
          icon: <CloudUploadIcon />,
          navDest: "/myuploads",
          valid: ["SUPERADMIN", "CUSTOMER", "USER", "INTERNAL"]
        }
      ],
    },
    {
      type: "Coordinate",
      icon: <AccessAlarmIcon />,
      items: [
        {
          name: <Trans i18nKey="DrawerInternal.recentSites"></Trans>,
          icon: <HistoryToggleOffIcon />,
          navDest: "/recentsites",
          valid: ["SUPERADMIN", "CUSTOMER", "USER", "INTERNAL"],
        },
        {
          name: <Trans i18nKey="DrawerInternal.coordinate"></Trans>,
          icon: <AccessTimeFilledIcon />,
          navDest: "/coordinate",
          valid: ["SUPERADMIN", "CUSTOMER", "USER", "INTERNAL"],
        },
        {
          name: <Trans i18nKey="DrawerInternal.qa"></Trans>,
          icon: <CheckBoxIcon />,
          navDest: "/qa",
          valid: ["SUPERADMIN", "CUSTOMER", "USER", "INTERNAL"],
        }, // Existing Feature To Add Back Later
        // {
        //   name: "Jobs",
        //   icon: <WorkIcon />,
        //   navDest: "/admin/jobs",
        //   valid: [ "SUPERADMIN", "CUSTOMER", "USER", "INTERNAL"],
        // },
      ],
    },
    {
      type: "Admin",
      icon: <FolderSharedIcon />,
      items: [
        {
          name: <Trans i18nKey="DrawerInternal.sitesMap"></Trans>,
          icon: <MapIcon />,
          navDest: "/map",
          valid: ["SUPERADMIN", "CUSTOMER", "USER", "INTERNAL"],
        },
        {
          name: <Trans i18nKey="common.allProjects"></Trans>,
          icon: <FolderOpenIcon />,
          navDest: "/admin/active-completed-projects",
          valid: ["SUPERADMIN", "CUSTOMER", "USER", "INTERNAL"],
        },
        {
          name: <Trans i18nKey="DrawerInternal.createProject"></Trans>,
          icon: <CreateNewFolderIcon />,
          navDest: "/admin/project/new",
          valid: ["SUPERADMIN", "CUSTOMER", "USER", "INTERNAL"],
        },
      ],
    },
    {
      type: "Users",
      icon: <PersonIcon />,
      items: [
        {
          name: <Trans i18nKey="DrawerInternal.users"></Trans>,
          icon: <GroupIcon />,
          navDest: "/admin/users",
          valid: ["SUPERADMIN", "CUSTOMER", "INTERNAL", "USER"],
        },
        {
          name: <Trans i18nKey="DrawerInternal.clients"></Trans>,
          icon: <AccountBoxIcon />,
          navDest: "/company",
          valid: ["SUPERADMIN", "CUSTOMER"],
        },
        {
          name: <Trans i18nKey="DrawerInternal.carriers"></Trans>,
          icon: <CellTowerIcon />,
          navDest: "/admin/carriers",
          valid: ["SUPERADMIN", "CUSTOMER", "INTERNAL", "USER"],
        },
        {
          name: <Trans i18nKey="DrawerInternal.customers"></Trans>,
          icon: <PersonAddAltIcon className="icon" />,
          navDest: "/admin/customers",
          valid: ["SUPERADMIN"],
        },
      ],
    },
    {
      type: "Help",
      icon: <HelpIcon />,
      items: [
        {
          name: <Trans i18nKey="common.help"></Trans>,
          icon: <HelpIcon />,
          navDest: "/help",
          valid: ["SUPERADMIN", "CUSTOMER", "USER", "INTERNAL"],
        },
      ],
    },
  ];

  const externalModel = [
    {
      type: "Navigation",
      icon: <FolderCopyIcon />,
      items: [
        {
          name: <Trans i18nKey="DrawerExternal.projects"></Trans>,
          icon: <FolderIcon className="icon" />,
          navDest: "/",
          valid: ["EXTERNAL"],
        },
        {
          name: <Trans i18nKey="DrawerExternal.allSites"></Trans>,
          icon: <MyLocationIcon className="icon" />,
          navDest: "/sites",
          valid: ["EXTERNAL"],
        },
        {
          name: <Trans i18nKey="common.map"></Trans>,
          icon: <MapIcon className="icon" />,
          navDest: "/map",
          valid: ["EXTERNAL"],
        },
        {
          name: <Trans i18nKey="common.help"></Trans>,
          icon: <HelpIcon className="icon" />,
          navDest: "/help",
          valid: ["EXTERNAL"],
        },
      ],
    },
  ];

  useEffect(() => {
    // Handle Customer
    if (userRole === "CUSTOMER") {
      axiosPrivateUserMng
        .get(`/customer/${USERID}`)
        .then((response) => {
          const subscriptionType = response?.data.subscriptionType;
          // Set nav items based on subscription
          switch (subscriptionType) {
            case "360Capture Enterprise":
              setNavItems(internalModel);
              break;
            default:
              let model = internalModel.filter((item) =>
                ["Dashboard", "Help", "My Captures"].includes(item.name)
              );
              setNavItems(model);
              break;
          }
        })
        .catch((err) => {
          captureException(err);
        });
    }

    // Handle Customer's Internal User
    else if (PARENTID !== "" && USERID !== "" && userRole === "USER") {
      axiosPrivateUserMng
        .get(`/customer/${PARENTID}`)
        .then((response) => {
          const subscriptionType = response?.data?.subscriptionType;
          // Set nav items based on subscription
          switch (subscriptionType) {
            case "360Capture Enterprise":
              setNavItems(internalModel);
              break;
            default:
              let model = internalModel.filter((item) =>
                ["Dashboard", "Help", "My Captures"].includes(item.name)
              );
              setNavItems(model);
              break;
          }
        })
        .catch((err) => {
          captureException(err);
        });
    } else if (userRole === "SUPERADMIN") {
      setNavItems(internalModel);
    }

    // Handle External User
    else if (userRole === "EXTERNAL") {
      setNavItems(externalModel);
    }
  }, [userRole, PARENTID, USERID]);

  const handleDrawerOpen = () => {
    setOpen(true);
    localStorage.setItem("drawerState", "true");
  };

  const handleDrawerClose = () => {
    setOpen(false);
    localStorage.setItem("drawerState", "false");
  };

  const logoutSelected = () => {
    setOpen(false);
    logout();
    navigate("/login", { replace: true });
  };

  // Notifications stuff
  const [anchorEl2, setAnchorEl2] = useState(null);

  const handleOpenNotif = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleCloseNotif = () => {
    setAnchorEl2(null);
  };

  // const testNotifications = [
  //   {id: 1, message: 'New Site Assigned: ATT-53201304', isRead: false},
  //   {id: 2, message: 'Status of Site 10942020 has changed to Client Approved', isRead: false},
  //   {id: 3, message: 'Upload complete for Site 10942019 (batch xyszws-242asd).', isRead: true},
  //   {id: 4, message: 'New Site Assigned: ATT-52129420', isRead: true},
  //   {id: 5, message: 'Upload has began processing for Site 10942019 (batch xyszws-242asd).', isRead: true}
  // ];

  // const unreadCount = testNotifications.filter(notification => !notification.isRead).length;

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="fixed" open={props.loggedIn ? open : false}>
        <Container maxWidth={false} disableGutters>
          <Toolbar>
            {props.loggedIn && (
              <Tooltip title="Open Menu">
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{
                    marginRight: 5,
                    ...(open && { display: "none" }),
                  }}
                >
                  <MenuIcon />
                </IconButton>
              </Tooltip>
            )}
            <img
              src={VTSLogo}
              className="VTSLogo"
              alt="Logo"
              id={"VTSLogo"}
              style={{
                width: "40px",
                height: "40px",
                padding: "0px",
                margin: "0px",
              }}
            />
            <Typography variant="p" component="div" sx={{ flexGrow: 1 }}>
              <strong>CrewSourcing™ with 360Capture™</strong>
            </Typography>

            {/* <div>
              <IconButton
                color="inherit"
                style={{
                  alignContent: "flex-end",
                  margin: "0px 0px",
                }}
                onClick={handleOpenNotif}
              >
                <Badge
                  badgeContent={unreadCount} color="secondary"
                >
                  <NotificationsIcon 
                  
                  />
                </Badge>
              </IconButton>
              <Menu
                anchorEl={anchorEl2}
                open={Boolean(anchorEl2)}
                onClose={handleCloseNotif}
                slotProps={{
                  paper: {
                    style: {
                      maxHeight: 64 * 6.5,
                      width: '60ch',
                    },
                  }
                }}
              >
                {testNotifications.length === 0 ? (
                  <MenuItem>
                    <ListItemText primary="No new notifications" />
                  </MenuItem>
                ):(
                  testNotifications.map((notification) => (
                    <MenuItem
                      key={notification.id}
                      sx={{
                        height:'60px'
                      }}
                    >
                      {notification.isRead === false ? <CircleIcon color="secondary" sx={{height:'10px', width:'10px', marginRight:'6px'}} /> : <></>}
                      <ListItemText primary={notification.message} />
                    </MenuItem>
                  ))
                )}
              </Menu>
            </div> */}


            <IconButton
              color="inherit"
              style={{
                alignContent: "flex-end",
                margin: "0px 10px",
              }}
              onClick={openPop ? handlePopoverClose : handlePopClick}
              aria-describedby={id}
            >
              <LanguageIcon />
              <Popover
                id={id}
                open={openPop}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                {lngs.map((lng) => {
                  return (
                    <Button
                      key={lng.language}
                      style={{
                        fontWeight:
                          i18n.resolvedLanguage === lng.language
                            ? "bold"
                            : "normal",
                        color: "inherit",
                      }}
                      type="submit"
                      onClick={() => {
                        i18n.changeLanguage(lng.language);
                        muiUtils.toggleLanguage(lng.language);
                        setCurrentLanguage(lng.language);
                        window.location.reload();
                      }}
                    >
                      {lng.nativeName}
                    </Button>
                  );
                })}
              </Popover>
            </IconButton>

            {userLoginStatus === true ? (
              <>
                {
                  <Button
                    color="inherit"
                    style={{
                      alignContent: "flex-end",
                    }}
                    onClick={logoutSelected}
                    data-cy="logout-button"
                  >
                    <Trans i18nKey="header.logout"></Trans>
                  </Button>
                }
              </>
            ) : (
              <></>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      {props.loggedIn && (
        <>
          <Drawer variant="permanent" open={open}>
            <DrawerHeader>
              <Tooltip title="Close Menu">
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === "rtl" ? (
                    <ChevronRightIcon />
                  ) : (
                    <ChevronLeftIcon />
                  )}
                </IconButton>
              </Tooltip>
            </DrawerHeader>
            <Divider />
            <List>
              {navItems.map((typeItems, index) => {
                return (
                  <NestedListItem
                    key={typeItems.type}
                    item={typeItems}
                    subItems={typeItems.items}
                    currentRoute={currentRoute}
                    collapse={open}
                    userRole={userRole}
                  />
                );
              })}
            </List>
          </Drawer>

          <Box
            sx={{ flexGrow: 1, p: 3, marginTop: "50px", marginRight: "60px" }}
          >
            <main>{props.children}</main>
          </Box>
        </>
      )}
      {userLoginStatus &&
        ["SUPERADMIN", "CUSTOMER", "USER"].includes(userRole) && (
          <SpeedDialHelper navigate={navigate} />
        )}
    </Box>
  );
};

export default AppHeader;
