import { createTheme, ThemeProvider } from "@mui/material";
import { createContext, useMemo, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { esES, enUS } from "@mui/material/locale";
import {
  enUS as DataGridUS,
  esES as DataGridES,
} from "@mui/x-data-grid/locales";

export const MUIWrapperContext = createContext({
  toggleLanguage: () => {},
});

export default function MUIWrapper(props) {
  const lightTheme = createTheme({
    typography: {
      fontFamily: ["Montserrat"],
    },
    palette: {
      mode: "light",
      primary: {
        main: "#001155",
        contrastText: "#f7f3ed",
      },
      secondary: {
        main: "#255fff",
        success: "#00b300",
        error: "#ff0000",
        grey: "#f7f3ed",
        lightGrey: "#c7c7c7",
        offWhite: "#f7f3ed",
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      }
    }
  });

  const darkTheme = createTheme({
    typography: {
      fontFamily: ["Montserrat"],
    },
    palette: {
      mode: "dark",
      primary: {
        main: "#f7f3ed",
        contrastText: "grey",
      },
      secondary: {
        main: "#255fff",
        success: "#00b300",
        error: "#ff0000",
        grey: "#5a5a5a",
        lightGrey: "#c7c7c7",
        offWhite: "#f7f3ed",
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      }
    }
  });

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const [theme, setTheme] = useState(prefersDarkMode ? darkTheme : lightTheme);
  const [language, setLanguage] = useState("en");
  const muiWrapperUtils = useMemo(
    () => ({
      toggleLanguage: (input) => {
        if (input === "es") {
          // Add the Spanish language to the theme
          setLanguage("es");
          setTheme(
            (prevMode) =>
              prevMode === createTheme(lightTheme)
                ? createTheme(darkTheme)
                : createTheme(lightTheme),
            esES,
            DataGridES
          );
        } else {
          if (prefersDarkMode) {
            setTheme(createTheme(darkTheme), enUS, DataGridUS);
          } else {
            setTheme(createTheme(lightTheme), enUS, DataGridUS);
          }
        }
      },
    }),
    []
  );

  return (
    <MUIWrapperContext.Provider value={muiWrapperUtils}>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </MUIWrapperContext.Provider>
  );
}
