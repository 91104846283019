import { createBrowserHistory } from "history";
import { Suspense, lazy } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import ProtectedRoutes from "../../ProtectedRoutes";
import AppHeader from "../../component/Header/Header";
import LoadingScreen from "../../component/LoadingScreen/LoadingScreen";

// --------- Lazy Loading Components ---------
const Login = lazy(() => import("../../container/Auth/Login/Login"));
const ForgotPassword = lazy(() =>
  import("../../container/Auth/ForgotPassword/ForgotPassword")
);
const SignUp = lazy(() => import("../../container/Auth/SignUp/SignUp"));

const UnknownRouter = () => {
  const browserHistory = createBrowserHistory();
  return (
    <Router history={browserHistory}>
      <AppHeader loggedIn={false} />
      <>
        <Routes>
          <Route
            path="*"
            element={
              <Suspense fallback={<LoadingScreen />}>
                <ProtectedRoutes />
              </Suspense>
            }
          >
            <Route
              path="*"
              element={
                <Suspense fallback={<LoadingScreen />}>
                  <Login />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/forgotpassword"
            element={
              <Suspense fallback={<LoadingScreen />}>
                <ForgotPassword />
              </Suspense>
            }
          />
          {/* <Route
            path="/sign-up"
            element={
              <Suspense fallback={<LoadingScreen />}>
                <SignUp />
              </Suspense>
            }
          /> */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </>
    </Router>
  );
};

export default UnknownRouter;
