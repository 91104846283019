import axios from "axios";
import "../config";
const BASE_URL =
  `https://` +
  global.config.workENV +
  ".vtscapture.com/api/v1/project-management";
const BASE_URL_File =
  `https://` +
  global.config.workENV +
  ".vtscapture.com/api/v1/files-management";
const BASE_URL_User =
  `https://` + global.config.workENV + ".vtscapture.com/api/v1/user-management";
const BASE_URL_Tips =
  `https://` + global.config.workENV + ".vtscapture.com/api/v1/tips-management";
const BASE_URL_Bentley =
  `https://` + global.config.workENV + ".vtscapture.com/api/v1/bentley";

const BASE_URL_Everypoint =
  `https://` + global.config.workENV + ".vtscapture.com/api/v1/everypoint";

export default axios.create({
  baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    mode: "no-cors",
  },
  // withCredentials: true
});

export const axiosPrivateFile = axios.create({
  baseURL: BASE_URL_File,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    mode: "no-cors",
  },
  // withCredentials: true
});

export const axiosPrivateUser = axios.create({
  baseURL: BASE_URL_User,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    mode: "no-cors",
  },
  // withCredentials: true
});

export const axiosPrivateTips = axios.create({
  baseURL: BASE_URL_Tips,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    mode: "no-cors",
  },
  // withCredentials: true
});

export const axiosPrivateBentley = axios.create({
  baseURL: BASE_URL_Bentley,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    mode: "no-cors",
  },
  // withCredentials: true
});

export const axiosPrivateEverypoint = axios.create({
  baseURL: BASE_URL_Everypoint,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    mode: "no-cors",
  },
  // withCredentials: true
});
