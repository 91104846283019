import { axiosPrivateUser } from "./axios";
import { useEffect, useContext } from "react";
import useRefreshToken from "./useRefreshToken";
import { AccountContext } from "../container/Auth/Accounts/Account";

const useAxiosPrivateUserMng = () => {
  const refresh = useRefreshToken();

  useEffect(() => {
    const requestIntercept = axiosPrivateUser.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          const AToken = localStorage.getItem("AuthToken");
          const IdToken = localStorage.getItem("IdToken");

          config.headers["Authorization"] = `Bearer ${AToken}`;
          config.headers["IdToken"] = `Bearer ${IdToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosPrivateUser.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        if (
          [400, 401, 403].includes(error?.response?.status) &&
          !prevRequest?.sent
        ) {
          prevRequest.sent = true;
          const newAccessToken = await refresh();
          prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return axiosPrivateUser(prevRequest);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivateUser.interceptors.request.eject(requestIntercept);
      axiosPrivateUser.interceptors.response.eject(responseIntercept);
    };
  }, [refresh]);

  return axiosPrivateUser;
};

export default useAxiosPrivateUserMng;
