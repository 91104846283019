export const LOGGEDIN = "LOGGEDIN";
export const LOGGEDOUT = "LOGGEDOUT";
export const NEWACCESSTOKEN = "NEWACCESSTOKEN";
export const USERMAIL = "USERMAIL";
export const ROLE = "ROLE";
export const TABLEDATA = "TABLEDATA";
export const SPINNER = "SPINNER";
export const OMNITEXT = "OMNITEXT";
export const USERTYPE = "USERTYPE";
export const USERID = "USERID";
export const PARENTID = "PARENTID";
export const INTERNAL = "INTERNAL";
