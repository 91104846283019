import CircularProgress from "@mui/material/CircularProgress";

const LoadingScreen = () => {
  return (
    <>
      <CircularProgress
        color="primary"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          marginTop: "-50px",
          marginLeft: "-50px",
          height: "100px",
          width: "100px",
        }}
      />
    </>
  );
};

export default LoadingScreen;
