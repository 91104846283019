import {
  LOGGEDIN,
  LOGGEDOUT,
  NEWACCESSTOKEN,
  USERMAIL,
  ROLE,
  TABLEDATA,
  SPINNER,
  OMNITEXT,
  USERTYPE,
  USERID,
  PARENTID,
  INTERNAL,
} from "./types";

const initialState = {
  authData: {},
  userLoginStatus: false,
  mail: "",
  role: "",
  tableData: [],
  reduxSpinner: false,
  omnibarText: "",
  loading: true,
  userType: "",
  USERID: "",
  PARENTID: "",
  INTERNAL: true,
};

const handleLogin = (state = initialState, action) => {
  switch (action.type) {
    case LOGGEDIN:
      return {
        ...state,
        // authData:action?.payload,
        userLoginStatus: true,
        loading: false,
      };
    case LOGGEDOUT:
      return {
        ...state,
        userLoginStatus: false,
        loading: false,
      };
    case NEWACCESSTOKEN:
      return {
        ...state,
        authData: {
          ...state.authData,
          accessToken: action.payload.accessToken,
        },
        loading: false,
      };
    case USERMAIL:
      return {
        ...state,
        mail: action?.payload,
        loading: false,
      };
    case ROLE:
      return {
        ...state,
        role: action?.payload,
        loading: false,
      };
    case TABLEDATA:
      return {
        ...state,
        tableData: action?.payload,
        loading: false,
      };
    case SPINNER:
      return {
        ...state,
        reduxSpinner: action?.payload,
        loading: false,
      };
    case OMNITEXT:
      return {
        ...state,
        omnibarText: action?.payload,
        loading: false,
      };
    case USERTYPE:
      return {
        ...state,
        userType: action?.payload,
        loading: false,
      };
    case USERID:
      return {
        ...state,
        USERID: action?.payload,
        loading: false,
      };
    case PARENTID:
      return {
        ...state,
        PARENTID: action?.payload,
        loading: false,
      };
    case INTERNAL:
      return {
        ...state,
        INTERNAL: action?.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default handleLogin;
