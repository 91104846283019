import * as Sentry from "@sentry/react";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import App from "./App";
import { Account } from "./container/Auth/Accounts/Account";
import reportWebVitals from "./reportWebVitals";
import store from "./utils";

// import i18n (needs to be bundled)
import "./utils/i18next/i18n";

// SENTRY.IO
const sentryEnv = global.config.workENV === "dev" ? "test" : "production";

Sentry.init({
  dsn: "https://8e80d44d2f344a49b56136b9fad4c19a@o4505029842042880.ingest.sentry.io/4505029937594368",
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  release: "portal@2.1",
  environment: sentryEnv,
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <Account>
      <App />
    </Account>
  </Provider>
);

reportWebVitals();
