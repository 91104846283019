import CssBaseline from "@mui/material/CssBaseline";
import { createBrowserHistory } from "history";
import { Suspense, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./App.scss";
import useAxiosPrivateUserMng from "./axios/useAxiosPrivateUserMng";
import LoadingScreen from "./component/LoadingScreen/LoadingScreen";
import TrialPopup from "./component/Modals/AnnoyingPopup/TrialPopup";
import { AccountContext } from "./container/Auth/Accounts/Account";
import {
  setLoggedIn,
  setParentId,
  setUserId,
  setUserMail,
  setUserRole,
} from "./utils/Authentication/AuthAction";
import MUIWrapper from "./utils/MUI/MUIWrapper";
import CustomerRouter from "./utils/Routing/CustomerRouter";
import ExternalUserRouter from "./utils/Routing/ExternalUserRouter";
import SuperAdminRouter from "./utils/Routing/SuperAdminRouter";
import UnknownRouter from "./utils/Routing/UnknownRouter";

function App() {
  const browserHistory = createBrowserHistory();
  const dispatch = useDispatch();
  const userRole = useSelector((state) => state.AuthReducer.role);
  const { getSession } = useContext(AccountContext);
  const [trial, setTrial] = useState(false);
  const [user, setUser] = useState(null);
  const [subscriptionType, setSubscriptionType] = useState(null);
  const axiosPrivateUserMng = useAxiosPrivateUserMng();
  const { USERID, PARENTID } = useSelector((state) => state.AuthReducer);

  useEffect(() => {
    getSession().then((session) => {
      const role = session[`custom:Role`];
      const userId = session[`custom:customerId`];
      const parentId = session[`custom:parent_id`];
      dispatch(setUserRole(role));
      dispatch(setUserId(userId));
      dispatch(setLoggedIn());
      dispatch(setUserMail(session.email));
      if (role === "USER" && parentId) {
        dispatch(setParentId(parentId));
        axiosPrivateUserMng.get(`/customer/${PARENTID}`).then((response) => {
          setSubscriptionType(response?.data?.subscriptionType);
          if (
            response?.data?.type === "TRIAL" &&
            !localStorage.getItem("trialPopupSeen")
          ) {
            setTrial(true);
            setUser(response?.data);
          }
        });
      } else if (role === "EXTERNAL" && parentId) {
      } else {
        dispatch(setParentId(userId));
        axiosPrivateUserMng.get(`/customer/${userId}`).then((response) => {
          setSubscriptionType(response?.data?.subscriptionType);
          if (
            response?.data?.type === "TRIAL" &&
            !localStorage.getItem("trialPopupSeen")
          ) {
            setTrial(true);
            setUser(response?.data);
          }
        });
      }
    });
  }, [userRole]);

  if (subscriptionType === null) {
    <LoadingScreen />;
  }

  const handleTrialClose = () => {
    setTrial(false);
    // Save that the user has seen the trial popup
    localStorage.setItem("trialPopupSeen", true);
  };

  return (
    <div
    // className="App"
    >
      <MUIWrapper>
        <CssBaseline />
        {userRole && ["CUSTOMER", "USER"].includes(userRole) && (
          <CustomerRouter />
        )}
        {userRole && userRole === "SUPERADMIN" && <SuperAdminRouter />}
        {userRole && userRole === "EXTERNAL" && <ExternalUserRouter />}
        {!userRole && <UnknownRouter />}
        {trial && (
          <TrialPopup open={trial} handleClose={handleTrialClose} user={user} />
        )}
      </MUIWrapper>
    </div>
  );
}

// for i18next integration when loading translations
export default function WrappedApp() {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <App />
    </Suspense>
  );
}
