import AccountBoxIcon from "@mui/icons-material/AccountBox";
import CellTowerIcon from "@mui/icons-material/CellTower";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import PersonIcon from "@mui/icons-material/Person";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import Fab from "@mui/material/Fab";

const SpeedDialHelper = ({ navigate }) => {
  const actions = [
    {
      icon: <CreateNewFolderIcon />,
      name: "Project",
      path: "/admin/project/new",
    },
    { icon: <PersonIcon />, name: "User", path: "/admin/users/new" },
    { icon: <AccountBoxIcon />, name: "Company", path: "/company/new" },
    { icon: <CellTowerIcon />, name: "Carrier", path: "/admin/add/carrier" },
  ];
  return (
    <SpeedDial
      ariaLabel="SpeedDial basic example"
      sx={{
        position: "fixed",
        bottom: 20,
        right: 20,
      }}
      icon={<SpeedDialIcon />}
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          tooltipOpen
          onClick={() => navigate(action.path)}
        />
      ))}
    </SpeedDial>
  );
};

export default SpeedDialHelper;
