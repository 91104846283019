import { createBrowserHistory } from "history";
import { Suspense, lazy } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import ProtectedRoutes from "../../ProtectedRoutes";
import AppHeader from "../../component/Header/Header";
import LoadingScreen from "../../component/LoadingScreen/LoadingScreen";

// --------- Lazy Loading Components ---------
const MyCaptures = lazy(() => import("../../container/Captures/MyCaptures"));
const MyUploads = lazy(() => import("../../container/Uploads/MyUploads"));
const AddCarrier = lazy(() => import("../../container/Carriers/AddCarrier"));
const CarrierDetail = lazy(() =>
  import("../../container/Carriers/CarrierDetail")
);
const Carriers = lazy(() => import("../../container/Carriers/Carriers"));
const Companies = lazy(() => import("../../container/Company/Companies"));
const CompanyDetails = lazy(() =>
  import("../../container/Company/CompanyDetails")
);
const CreateCompany = lazy(() =>
  import("../../container/Company/CreateCompany")
);
const Dashboard = lazy(() => import("../../container/Dashboard/Dashboard"));
const Help = lazy(() => import("../../container/Help/Help"));
const Jobs = lazy(() => import("../../container/Jobs/Jobs"));
const LiveCoordinatorList = lazy(() =>
  import("../../container/LiveCoordinator/LiveCoordinatorList")
);
const InternalSitesMap = lazy(() =>
  import("../../container/Map/InternalSitesMap")
);
const AllProjects = lazy(() =>
  import("../../container/Projects/AllProjects/AllProjects")
);
const CreateProject = lazy(() =>
  import("../../container/Projects/CreateProject/CreateProject")
);
const InternalProjects = lazy(() =>
  import("../../container/Projects/InternalProjects/InternalProjects")
);
const ProjectDetails = lazy(() =>
  import("../../container/Projects/ProjectDetails/ProjectDetails")
);
const QA = lazy(() => import("../../container/QA/QA"));
const MySites = lazy(() => import("../../container/Sites/MySites/MySites"));
const RecentSites = lazy(() =>
  import("../../container/Sites/RecentSites/RecentSiteList")
);
const SiteDetails = lazy(() =>
  import("../../container/Sites/SiteDetails/SiteDetails")
);
const CreateUser = lazy(() =>
  import("../../container/User/CreateUser/CreateUser")
);
const UserDetails = lazy(() =>
  import("../../container/User/UserDetails/UserDetails")
);
const Users = lazy(() => import("../../container/User/Users/Users"));
const CustomerRouter = () => {
  const browserHistory = createBrowserHistory();

  return (
    <Router history={browserHistory}>
      <AppHeader loggedIn={true}>
        <>
          <Routes>
            <Route path="/" element={<ProtectedRoutes />}>
              <Route
                path="/"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <InternalProjects />
                  </Suspense>
                }
              />
              <Route
                path="/mysites"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <MySites />
                  </Suspense>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <Dashboard />
                  </Suspense>
                }
              />
              <Route
                path="/recentsites"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <RecentSites />
                  </Suspense>
                }
              />
              <Route
                path="/mycaptures"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <MyCaptures />
                  </Suspense>
                }
              />
              <Route
                path="/myuploads"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <MyUploads />
                  </Suspense>
                }
              />
              <Route
                path="/map"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <InternalSitesMap />
                  </Suspense>
                }
              />
              <Route
                path="/admin/active-completed-projects"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <AllProjects />
                  </Suspense>
                }
              />
              <Route
                path="/admin/carriers"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <Carriers />
                  </Suspense>
                }
              />
              <Route
                path="/qa"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <QA />
                  </Suspense>
                }
              />
              <Route
                path="/admin/projects/siteDetails/:id"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <SiteDetails />
                  </Suspense>
                }
              />
              <Route
                path="/admin/siteDetails/:id"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <SiteDetails
                      title=""
                      modal={true}
                      externalUser={false}
                      admin={true}
                    />
                  </Suspense>
                }
              />
              <Route
                path="/admin/project/new"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <CreateProject />
                  </Suspense>
                }
              />
              <Route
                path="/admin/add/carrier"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <AddCarrier />
                  </Suspense>
                }
              />
              <Route
                path="/admin/carrier/:id"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <CarrierDetail />
                  </Suspense>
                }
              />
              <Route
                path="/admin/project/details/:id"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <ProjectDetails />
                  </Suspense>
                }
              />
              <Route
                path="/admin/users"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <Users />
                  </Suspense>
                }
              />
              <Route
                path="/admin/users/new"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <CreateUser />
                  </Suspense>
                }
              />
              <Route
                path="/admin/users/:id"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <UserDetails />
                  </Suspense>
                }
              />
              <Route
                path="/company"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <Companies />
                  </Suspense>
                }
              />
              <Route
                path="/company/:id"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <CompanyDetails />
                  </Suspense>
                }
              />
              <Route
                path="/company/new"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <CreateCompany />
                  </Suspense>
                }
              />
              <Route
                path="/admin/jobs"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <Jobs />
                  </Suspense>
                }
              />
              <Route
                path="/coordinate"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <LiveCoordinatorList />
                  </Suspense>
                }
              />
              <Route
                path="/help"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <Help />
                  </Suspense>
                }
              />
            </Route>
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </>
      </AppHeader>
    </Router>
  );
};

export default CustomerRouter;
