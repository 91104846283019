import {
  CognitoRefreshToken,
  CognitoUserPool,
} from "amazon-cognito-identity-js";

const poolData = {
  UserPoolId: import.meta.env.VITE_APP_USER_POOL_ID,
  ClientId: import.meta.env.VITE_APP_CLIENT_ID,
};

const userPool = new CognitoUserPool(poolData);

const useRefreshToken = () => {
  const refresh = async (cognitoUser) => {
    const user = userPool.getCurrentUser();
    const refreshToken = localStorage.getItem("refreshToken");
    var token = new CognitoRefreshToken({ RefreshToken: refreshToken });
    user.refreshSession(token, function (err, session) {
      if (err) {
        return;
      }
      var idToken = session?.idToken?.jwtToken;
      var newAccessToken = session?.accessToken?.jwtToken;
      var newrefreshtoken = session?.refreshToken?.token;
      localStorage.setItem("AuthToken", newAccessToken);
      localStorage.setItem("IdToken", idToken);
      localStorage.setItem("refreshToken", newrefreshtoken);
    });
    return cognitoUser;
  };
  return refresh;
};

export default useRefreshToken;
