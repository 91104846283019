import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useState } from "react";

const TrialPopup = ({ open, handleClose, user }) => {
  const [days, setDays] = useState(0);

  const calcRemainingDays = (date) => {
    // Get the current date
    let currentDate = new Date();
    // Get the date the trial started
    let trialDate = new Date(date);
    // Get the difference in time
    let difference = currentDate.getTime() - trialDate.getTime();
    // Get the difference in days
    let days = difference / (1000 * 3600 * 24);
    // Get the remaining days
    let remainingDays = 30 - days;
    // Format to no decimal places
    remainingDays = remainingDays.toFixed(0);
    if (remainingDays < 0) {
      remainingDays = 0;
    }
    return remainingDays;
  };

  useEffect(() => {
    if (user) {
      setDays(calcRemainingDays(user.created_at));
    }
  }, []);

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (days !== 0) {
          handleClose();
        }
      }}
      disableBackdropClick={true}
    >
      <DialogTitle>Trial Period Update</DialogTitle>
      <DialogContent>
        {days > 0 ? (
          <p>
            You have {days} days left in your trial period. Please consider
            upgrading to an Enterprise Subscription to continue using our
            services.
          </p>
        ) : (
          <p>
            Your trial period has expired. Please contact support@vtscans.com to
            continue using our platform.
          </p>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={days === 0}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TrialPopup;
