import CircularProgress from "@mui/material/CircularProgress";
import { lazy, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { AccountContext } from "./container/Auth/Accounts/Account";
import { setUserMail } from "./utils/Authentication/AuthAction";
const Login = lazy(() => import("./container/Auth/Login/Login"));

const ProtectedRoutes = (props) => {
  const dispatch = useDispatch();
  // const userAuth = useSelector((state) => state.AuthReducer.userLoginStatus);
  const userAwsMail = useSelector((state) => state.AuthReducer.mail);
  const { getSession } = useContext(AccountContext);
  // const isAuthenticated = localStorage.getItem("isAuthenticated");
  const [currentUserid, setcurrentUser] = useState();

  // New
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const gettheSession = () => {
      getSession()
        .then((session) => {
          setcurrentUser(session);
          dispatch(setUserMail(session.email));
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };
    gettheSession();
  }, [getSession, dispatch]);

  return (
    <>
      {
        // If it's loading, display a progress bar
        loading && (
          <CircularProgress
            color="primary"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-50px",
              marginLeft: "-50px",
              height: "100px",
              width: "100px",
            }}
          />
        )
      }
      {
        // If the user is logged in, display the protected page
        !loading && userAwsMail && currentUserid && (
          <Outlet className="Outlet" />
        )
      }
      {
        // If the user isn't logged in, display the login page
        !loading && !userAwsMail && !currentUserid && (
          <Login className="Login" />
        )
      }
    </>
  );
};

export default ProtectedRoutes;
