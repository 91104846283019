import {
  LOGGEDIN,
  LOGGEDOUT,
  NEWACCESSTOKEN,
  USERMAIL,
  ROLE,
  TABLEDATA,
  SPINNER,
  OMNITEXT,
  USERTYPE,
  USERID,
  PARENTID,
  INTERNAL,
} from "./types";

export const setLoggedIn = () => async (dispatch) => {
  dispatch({
    type: LOGGEDIN,
    payload: true,
  });
};

export const setLoggedOut = () => async (dispatch) => {
  dispatch({
    type: LOGGEDOUT,
    payload: false,
  });
};

export const setNewAccessToken = (data) => async (dispatch) => {
  dispatch({
    type: NEWACCESSTOKEN,
    payload: data,
  });
};

export const setUserMail = (mail) => async (dispatch) => {
  dispatch({
    type: USERMAIL,
    payload: mail,
  });
};

export const setUserRole = (role) => async (dispatch) => {
  dispatch({
    type: ROLE,
    payload: role,
  });
};

export const setTableData = (data) => async (dispatch) => {
  dispatch({
    type: TABLEDATA,
    payload: data,
  });
};

export const setSpinners = (data) => async (dispatch) => {
  dispatch({
    type: SPINNER,
    payload: data,
  });
};

export const setOmniText = (data) => async (dispatch) => {
  dispatch({
    type: OMNITEXT,
    payload: data,
  });
};

export const setUserType = (data) => async (dispatch) => {
  dispatch({
    type: USERTYPE,
    payload: data,
  });
};

export const setUserId = (data) => async (dispatch) => {
  dispatch({
    type: USERID,
    payload: data,
  });
};

export const setParentId = (data) => async (dispatch) => {
  dispatch({
    type: PARENTID,
    payload: data,
  });
};

export const setInternal = (data) => async (dispatch) => {
  dispatch({
    type: INTERNAL,
    payload: data,
  });
};
