import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Collapse } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const NestedListItem = ({ item, subItems, currentRoute, userRole }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    // If the current route is a subItem, open the parent item
    if (subItems.map((item) => item.navDest).includes(currentRoute)) {
      setOpen(true);
    }
  }, [currentRoute]);

  return (
    <>
      <ListItemButton
        onClick={handleClick}
        sx={{
          backgroundColor: open ? theme.palette.secondary.lightGrey : "",
          color: theme.palette.primary.main,
        }}
        data-cy="sidebar-button"
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.type} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} key={item.type} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {subItems
            .filter(
              // Filter out subitems that do not contain the userRole in their valid array
              (subItem) => subItem.valid.includes(userRole)
            )
            .map((item, index) => {
              return (
                <ListItemButton
                  key={index}
                  onClick={() => {
                    navigate(item.navDest);
                  }}
                  sx={{
                    backgroundColor:
                      currentRoute === item.navDest
                        ? theme.palette.secondary.main
                        : "",
                    color:
                      currentRoute === item.navDest
                        ? theme.palette.secondary.contrastText
                        : theme.palette.primary.main,
                  }}
                  data-cy="sidebar-link"
                >
                  <ListItemIcon
                    sx={{
                      color:
                        currentRoute === item.navDest
                          ? theme.palette.secondary.contrastText
                          : theme.palette.primary.main,
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.name} />
                </ListItemButton>
              );
            })}
        </List>
      </Collapse>
      <Divider />
    </>
  );
};

export default NestedListItem;
