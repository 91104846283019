import { createContext } from "react";
import { useDispatch } from "react-redux";
import Pool from "../../../UserPool";
import {
  setInternal,
  setLoggedOut,
  setUserMail,
} from "../../../utils/Authentication/AuthAction";
import useAxiosPrivateUserMng from "../../../axios/useAxiosPrivateUserMng";

const AccountContext = createContext();

const Account = (props) => {
  const dispatch = useDispatch();
  const axiosPrivateUser = useAxiosPrivateUserMng();

  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            reject();
          } else {
            const attributes = await new Promise((resolve, reject) => {
              user.getUserAttributes((err, attributes) => {
                if (err) {
                  reject(err);
                } else {
                  const results = {};
                  for (let attribute of attributes) {
                    const { Name, Value } = attribute;
                    results[Name] = Value;
                  }

                  if (
                    ["SUPERADMIN", "CUSTOMER"].includes(results["custom:Role"])
                  ) {
                    dispatch(setInternal(true));
                  } else {
                    // Get the email for the user
                    let email = results.email;

                    // Get the users for the company
                    let customerId = results["custom:parent_id"];

                    axiosPrivateUser
                      .get(`users?customerId=${customerId}`)
                      .then((res) => {
                        // Filter the user based on the email
                        let user = res.data.data.filter(
                          (user) => user.email === email
                        );
                        // Get the company details to determine internal/external
                        if (user[0].companyDetails.type === "EXTERNAL") {
                          dispatch(setInternal(false));
                        } else {
                          dispatch(setInternal(true));
                        }
                      });
                  }

                  resolve(results);
                }
              });
            });
            resolve({ user, ...session, ...attributes });
          }
        });
      } else {
        reject();
      }
    });
  };

  const logout = () => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
      localStorage.clear();
      dispatch(setLoggedOut());
      dispatch(setUserMail(""));
      dispatch(setInternal(false));

      window.location.reload();
    } else {
      window.location.replace("/login");
    }
  };

  return (
    <AccountContext.Provider value={{ getSession, logout }}>
      {props.children}
    </AccountContext.Provider>
  );
};

export { Account, AccountContext };
