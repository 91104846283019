import { createBrowserHistory } from "history";
import { Suspense, lazy } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import ProtectedRoutes from "../../ProtectedRoutes";
import AppHeader from "../../component/Header/Header";
import LoadingScreen from "../../component/LoadingScreen/LoadingScreen";

// --------- Lazy Loading Components ---------
const ExternalProjects = lazy(() =>
  import("../../container/Projects/ExternalProjects/ExternalProjects")
);
const Map = lazy(() => import("../../container/Map/ExternalSitesMap"));
const SiteList = lazy(() =>
  import("../../container/Projects/ExternalProjects/SiteList")
);
const ExternalSiteDetails = lazy(() =>
  import("../../container/Sites/ExternalSiteList/ExternalSiteDetails")
);
const AllSitesList = lazy(() =>
  import("../../container/Sites/ExternalSiteList/AllSitesList")
);
const Help = lazy(() => import("../../container/Help/Help"));

const ExternalUserRouter = () => {
  const browserHistory = createBrowserHistory();

  return (
    <Router history={browserHistory}>
      <AppHeader loggedIn={true}>
        <>
          <Routes>
            <Route path="/" element={<ProtectedRoutes />}>
              <Route
                path="/"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <ExternalProjects />
                  </Suspense>
                }
              />
              <Route
                path="/map"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <Map />
                  </Suspense>
                }
              />
              <Route
                path="/external/projects/:id"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <SiteList />
                  </Suspense>
                }
              />
              <Route
                path="/external/site/:id"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <ExternalSiteDetails />
                  </Suspense>
                }
              />
              <Route
                path="/sites"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <AllSitesList />
                  </Suspense>
                }
              />
              <Route
                path="/help"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <Help />
                  </Suspense>
                }
              />
            </Route>
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </>
      </AppHeader>
    </Router>
  );
};

export default ExternalUserRouter;
